<template>
    <section>
        <div class="row no-print">
            <b-col>
                <b-form-group class="form-group">
                    <label for="">Período</label>
                    <Periods @select="handlePeriod" :monthSelected="monthSelected" />
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group class="form-group">
                    <label for="">Situação do agendamento</label>
                    <CheckBoxSelect label="Selecionar situação" placeholder="Selecionar situação"
                        :value="filter.status" :options="situations" trackBy="value" optionLabel="label"
                        @onChange="setSituationFilter" />
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group class="form-group">
                    <label for="search">Paciente / Item</label>
                    <div class="input-wrapper">
                        <Search class="icon" />
                        <b-form-input autocomplete="off" id="search" v-model="filter.query" class="form-control"
                            placeholder="Pesquisar paciente ou item" />
                    </div>
                </b-form-group>
            </b-col>

            <b-col>
                <b-form-group id="health_plan">
                    <label for="health_plan" id="input-title">Convênio</label>
                    <v-autocomplete placeholder="Todos os convênios" :options="healthPlans"
                        v-model="filter.healthPlan" />
                </b-form-group>
            </b-col>

            <b-col>
                <b-form-group>
                    <label for="professional">Executante</label>
                    <multiselect class="with-border" :options="professionals" :value="professional" label="label"
                        @search-change="getProfessionalsAuditDebounce" @select="selectProfessional" :show-labels="false"
                        id="professional" placeholder="Selecionar">
                        <template slot="caret">
                            <div class="chevron">
                                <ChevronDown />
                            </div>
                        </template>
                        <template slot="noOptions">
                            Digite para pesquisar um profissional
                        </template>
                        <template slot="noResult"> Nenhum resultado </template>
                    </multiselect>
                </b-form-group>
            </b-col>

        </div>
    </section>
</template>
<script>
import { getCurrentClinic } from '@/utils/localStorageManager';
import debounce from 'lodash/debounce';
export default {
    components: {
        'v-autocomplete': () => import('@/components/Autocomplete'),
        Search: () => import('@/assets/icons/search.svg'),
        Periods: () => import('@/components/General/Periods'),
        ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
        CheckBoxSelect: () => import('@/components/CheckboxSelect'),
    },
    data() {
        return {
            clinic: getCurrentClinic(),
            query: '',
            langDatePicker: {
                formatLocale: {
                    weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']
                }
            },
            filter: {
                healthPlan: null,
                professional: null,
                start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
                end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
                query: '',
                status: this.getSituationOptions(),
                itemType: null,

            },
            daySelected: false,
            healthPlans: [],
            professionals: [],
            monthSelected: true,
            professional: null,
            situations: this.getSituationOptions()
        };
    },
    created() {
        this.getProfessionalsAuditDebounce = debounce(
            this.getProfessionalsAudit,
            500
        );
    },
    mounted() {
        this.getClinicHealthPlans();
    },
    methods: {
        resetChevronState() {
            if (this.chevronState === true) {
                const chevron = document.getElementById('chevron');
                chevron.classList.toggle('chevron-active');
                this.chevronState = false;
            }
        },
        handlePeriod(value) {
            this.filter.start = value.start;
            this.filter.end = value.end;
        },
        async getClinicHealthPlans() {
            try {
                const { data } = await this.api.getAllClinicHealthPlans(this.clinic.id);

                this.healthPlans = data.map(clinicHealthPlan => ({
                    label: clinicHealthPlan?.health_plan?.fantasy_name,
                    value: clinicHealthPlan?.health_plan?.id
                })).sort((a, b) => {
                    if (a.label < b.label) return -1;
                    else return 1;
                });

            } catch (error) {
                console.error(error);
            }
        },
        selectProfessional(professional) {
            this.professional = professional;
            this.filter.professional = professional.value;
            if (!professional.value) {
                this.filters.actionDescription = null;
            }
        },
        async getProfessionalsAudit(query) {
            if (query.length > 2) {
                try {
                    const response = await this.api.getProfessionals(
                        null,
                        this.clinic.id,
                        query
                    );
                    this.professionals = response.data.data.map(professional => {
                        return {
                            label: professional.name,
                            value: professional.id
                        };
                    });
                    this.professionals.unshift({
                        label: 'Selecionar',
                        value: null
                    });
                } catch (error) {
                    this.$toast.error(error.message);
                }
            }
        },
        getSituationOptions() {
            return [
                { label: 'Todos', value: null },
                { label: 'Sem situação', value: 'SCHEDULED' },
                { label: 'Compareceu', value: 'ATTEND' },
                { label: 'Atendimeno finalizado', value: 'FINISHED' },
                { label: 'Confirmado', value: 'CONFIRMED' },
                { label: 'Confirmado pelo paciente', value: 'CONFIRMED_BY_PATIENT' },
                { label: 'Faltou', value: 'MISSED' },
                { label: 'Cancelado', value: 'CANCELLED' },
                { label: 'Cancelado pelo paciente', value: 'CANCELLED_BY_PATIENT' },
                { label: 'Aguardando atendimento', value: 'WAITING' },
                { label: 'Em atendimento médico', value: 'HAPPENING' },
                { label: 'Em exames', value: 'EXAMINATING' },
                { label: 'Dilatando', value: 'DILATING' },
                { label: 'Pré-consulta finalizada', value: 'PRE_FINISHED' },
                { label: 'Em pré-consulta', value: 'IN_PRE_CONSULTATION' },
                { label: 'Finalizado', value: 'FINISHED' }
            ];
        },
        setSituationFilter(value) {
            this.filter.status = value;
        },

    },
    watch: {
        filter: {
            handler() {
                this.$emit('changeFilter', this.filter);
            },
            deep: true
        }
    }
};
</script>
<style lang="scss" scoped>
.icon {
    width: 20px;
    height: 20px;
}

.input-wrapper {
    position: relative;
}

.input-wrapper .icon {
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
}

.input-wrapper .form-control {
    padding-left: 35px;
}
</style>